import React from "react";

export default class LandingHowWhy extends React.Component {
  render() {
    return (
      <div className="relative bg-white pt-16 pb-32 overflow-hidden">
        <div className="relative" style={{ paddingTop: "9rem" }}>
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
              <div>
                <div className="mt-6">
                  <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                    About Us
                  </h2>
                  <p className="mt-4 text-lg text-gray-500">
                    Headquartered in Chicago, DFD turns critical and complex
                    issues into opportunities for growth. We don’t shy away from
                    challenging situations. We identify the highest and best use
                    sites for transformative urban projects. Our challenging
                    times make the need for creative thinking, access to
                    information, and innovative partners a necessity. DFD is
                    poised to lead a new phase of growth through the challenges
                    that lie ahead.
                  </p>
                </div>
              </div>
              <div className="mt-8 border-t border-gray-200 pt-6">
                <blockquote>
                  <div>
                    <p className="text-base text-gray-500">
                      &ldquo;Eventually, I think Chicago will be the most
                      beautiful great city left in the world.&rdquo;
                    </p>
                  </div>
                  <footer className="mt-3">
                    <div className="flex items-center space-x-3">
                      <div className="text-base font-medium text-gray-700">
                        Frank Llyod Wright
                      </div>
                    </div>
                  </footer>
                </blockquote>
              </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0">
              <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <img
                  className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left- lg:h-full lg:w-auto lg:max-w-none"
                  src="https://images.pexels.com/photos/333645/pexels-photo-333645.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
                  alt="Inbox user interface"
                  style={{ boxShadow: "0 7px 15px  rgba(0,0,0,.5)" }}
                />
              </div>
            </div>
          </div>
        </div>
        <div style={{ paddingTop: "10rem" }}></div>
      </div>
    );
  }
}
