import React from 'react';
import  PressReleaseDash from "../components/pressers/PressReleaseDash";

export default class Pressers extends React.Component{

    render(){
        return(
            <div>
                <PressReleaseDash/>
            </div>
        )
    }

}