import React from "react";
import logo from "../../media/logo.png";

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: true
    };
  }

  determineSelected(routeName) {
    if (routeName === this.props.selected) {
      return "border-indigo-500 text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium";
    } else {
      return "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium";
    }
  }

  determineMenuClass(name) {
    if (name === "x") {
      if (this.state.menuOpen === false) {
        return "block h-6 w-6";
      } else {
        return "hidden h-6 w-6";
      }
    }

    if (name === "hamburger") {
      if (this.state.menuOpen === false) {
        return "hidden h-6 w-6";
      } else {
        return "block h-6 w-6";
      }
    }
    if (name == "menu") {
      if (this.state.menuOpen === false) {
        return "sm:hidden";
      } else {
        return "sm:hidden hidden";
      }
    }
  }

  updateMenuOpen = () => {
    this.setState(function(state) {
      return {
        menuOpen: !this.state.menuOpen
      };
    });
  };

  render() {
    return (
      <nav className="bg-white shadow-lg">
        <div className="max-w-9xl mx-auto px-4 sm:px-6 lg:px-8 xl:px-8">
          <div className="flex justify-between h-16">
            <div className="flex">
              <div className="flex-shrink-0 flex items-center">
                <a href="/" onClick={() => this.props.update("/")}>
                  <img
                    className="block lg:hidden h-8 w-auto"
                    src={logo}
                    alt="Workflow"
                  />
                  <img
                    className="hidden lg:block h-8 w-auto"
                    src={logo}
                    alt="Workflow"
                  />
                </a>
              </div>
              <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                {/* <a
                  onClick={() => this.props.update("root")}
                  href="/"
                  className={this.determineSelected("root")}
                >
                  Home
                </a> */}
                {/* <a
                  onClick={() => this.props.update("What We Do")}
                  href="/what-we-do"
                  className={this.determineSelected("What We Do")}
                >
                  What We Do
                </a>
                <a
                  onClick={() => this.props.update("Investments")}
                  href="/investment"
                  className={this.determineSelected("Investments")}
                >
                  Investment Criteria
                </a> */}
                {/* <a
                  onClick={() => this.props.update("About Us")}
                  href="/about"
                  className={this.determineSelected("About Us")}
                >
                  About Us
                </a> */}
                <a
                  onClick={() => this.props.update("News")}
                  href="/news"
                  className={this.determineSelected("News")}
                >
                  News
                </a>
              </div>
            </div>
            <div className="hidden sm:ml-6 sm:flex sm:items-center">
              <a
                onClick={() => this.props.update("Contact Us")}
                href="/contact"
                className={this.determineSelected("Contact Us")}
              >
                Contact Us
              </a>
            </div>
            <div className="-mr-2 flex items-center sm:hidden">
              {/* <!-- Mobile menu button --> */}
              <button
                onClick={this.updateMenuOpen}
                type="button"
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                <svg
                  className={this.determineMenuClass("hamburger")}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
                <svg
                  className={this.determineMenuClass("x")}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        {/* <!-- Mobile menu, show/hide based on menu state. --> */}
        <div className={this.determineMenuClass("menu")} id="mobile-menu">
          <div className="pt-2 pb-3 space-y-1">
            {/* <!-- Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" --> */}
            {/* <a
              href="/home"
              className="bg-indigo-50 border-indigo-500 text-indigo-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
            >
              Home
            </a>
            <a
              href="/projects"
              className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
            >
              Projects
            </a> */}
            {/* <a
              href="/about"
              className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
            >
              About Us
            </a> */}
            <a
              href="/news"
              className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
            >
              News
            </a>
          </div>
          <div className="pt-4 pb-3 border-t border-gray-200">
            <div className="mt-3 space-y-1">
              <a
                href="/contact"
                className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navbar;
