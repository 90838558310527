import React from 'react';
import AboutUs from '../components/about/AboutUs';
import CTA from '../components/about/CTA'


export default class AboutUsView extends React.Component{

render() {
    return (
        <div>
            <AboutUs/>
            <CTA/>
        </div>
    )
}

}