import React, { Component } from "react";
import LandingHero from "../components/content/landing/LandingHero";
import LandingCards from "../components/content/landing/LandingCards";
import LandingHowWhy from "../components/content/landing/LandingHowWhy";
import Leadership from "../components/content/landing/Leadership";
import LandingAproach from "../components/content/landing/LandingApproach";

export default class HomePage extends React.Component {
  render() {
    return (
      <div>
        <LandingHero />
        <LandingCards />
        <LandingHowWhy />
        <Leadership />
        <LandingAproach />
      </div>
    );
  }
}
