import React from "react";
import { Transition } from "@headlessui/react";
import IIGNAPresser from "../../media/releases/IIGNAPressRelease.jpg";

export default class PressReleaseDash extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
      profileOpen: false,
      selectedRoute: "IIGNA Partnership"
    };
    this.handleMenuOpen.bind(this);
    this.handleProfileOpen.bind(this);
    this.handleRouteChange.bind(this);
  }

  handleProfileOpen = () => {
    this.setState(function(state) {
      return {
        profileOpen: !this.state.profileOpen
      };
    });
  };

  handleMenuOpen = () => {
    this.setState(function(state) {
      return {
        menuOpen: !this.state.menuOpen
      };
    });
  };

  determineInsetClass = () => {
    if (!this.state.menuOpen) {
      return "lg:hidden hidden";
    } else {
      return "lg:hidden";
    }
  };

  handleRouteChange = route => {
    this.setState(function(state) {
      return {
        selectedRoute: route
      };
    });
  };

  determineSelectedContent = route => {
    console.log(route);
    console.log(this.state.selectedRoute);
    if (route === this.state.selectedRoute) {
      return "bg-cyan-800 text-white group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md focus:outline-none";
    } else {
      return "group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-cyan-100 hover:text-white hover:bg-cyan-600 focus:outline-none";
    }
  };

  render() {
    return (
      <div className="h-screen flex overflow-hidden bg-gray-100">
        <div className={this.determineInsetClass()}>
          <div className="fixed inset-0 flex z-40">
            <Transition
              show={this.state.menuOpen}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg">
                <div
                  className="absolute inset-0 bg-gray-600 opacity-75"
                  aria-hidden="true"
                ></div>
              </div>
            </Transition>
            <Transition
              show={this.state.menuOpen}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div
                className="relative flex-1 flex h-full flex-col max-w-xs w-full pt-5 pb-4 bg-cyan-700"
                style={{
                  backgroundColor:
                    "linear-gradient(90deg, rgb(9, 121, 116) 0%, rgb(153, 221, 255) 0%, rgb(0, 212, 255) 100%)"
                }}
              >
                <div
                  onClick={() => this.handleMenuOpen()}
                  className="absolute top-0 right-0 -mr-12 pt-2"
                >
                  <a
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => this.handleMenuOpen(!this.state.menuOpen)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <svg
                      className="h-6 w-6 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </a>
                </div>
                <div className="flex-shrink-0 flex items-center px-4">
                  <h1>News</h1>
                </div>
                <nav
                  className="mt-5 flex-shrink-0 h-full divide-y divide-cyan-800 overflow-y-auto"
                  aria-label="Sidebar"
                >
                  {/* <div className="px-2 space-y-1">
                    <button className="text-cyan-100 hover:text-white hover:bg-cyan-600 group flex items-center px-2 py-2 text-base font-medium rounded-md">
                      <svg
                        className="mr-4 h-6 w-6 text-cyan-200"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                        />
                      </svg>
                      IIGNA Partnership
                    </button> */}
                  {/* </div> */}
                </nav>
              </div>
            </Transition>

            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* <!-- Dummy element to force sidebar to shrink to fit close icon --> */}
            </div>
          </div>
          {/* </Transition> */}
        </div>

        {/* <!-- Static sidebar for desktop --> */}
        <div className="hidden lg:flex lg:flex-shrink-0">
          <div className="flex flex-col w-64">
            {/* <!-- Sidebar component, swap this element with another sidebar if you like --> */}
            <div
              className="flex flex-col flex-grow bg-cyan-700 pt-5 pb-4 overflow-y-auto"
              style={{
                background:
                  "linear-gradient(90deg, rgb(9, 121, 116) 0%, rgb(153, 221, 255) 0%, rgb(0, 212, 255) 100%)"
              }}
            >
              <div className="flex-shrink-0 flex items-center px-4">
                <h1>News</h1>
              </div>
              <nav
                className="mt-5 flex-1 flex flex-col divide-y divide-cyan-800 overflow-y-auto"
                aria-label="Sidebar"
              >
                <div className="px-2 space-y-1">
                  {/* <button
                    onClick={() => this.handleRouteChange("IIGNA Partnership")}
                    className={this.determineSelectedContent(
                      "IIGNA Partnership"
                    )}
                  >
                    <svg
                      className="mr-4 h-6 w-6 text-cyan-200"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                      />
                    </svg>
                    IIGNA Partnership
                  </button> */}
                </div>
              </nav>
            </div>
          </div>
        </div>

        <div className="flex-1 overflow-auto focus:outline-none " tabIndex="0">
          <div className="relative z-10 flex-shrink-0 flex h-16 bg-white border-b border-gray-200 lg:border-none shadow">
            <button
              onClick={() => this.handleMenuOpen()}
              className="px-4 border-r border-gray-200 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500 lg:hidden"
            >
              <span className="sr-only">Open sidebar</span>
              {/* <!-- Heroicon name: outline/menu-alt-1 --> */}
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h8m-8 6h16"
                />
              </svg>
            </button>
            <div className="flex-1 px-4 flex justify-between sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
              <div className="flex-1 flex"></div>
              <div className="ml-4 flex items-center md:ml-6"></div>
            </div>
          </div>
          {/* <main className="flex-1 relative pb-8 z-0 overflow-y-auto">
            <img src={IIGNAPresser} />
          </main> */}
        </div>
      </div>
    );
  }
}
