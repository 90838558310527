import React, { Component } from "react";

export default class LandingCards extends React.Component {
  render() {
    return (
      <div
        className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8"
        style={{ paddingTop: "10rem", paddingBottom: "10rem" }}
      >
        <div className="absolute inset-0">
          <div className="bg-white h-1/3 sm:h-2/3"></div>
        </div>
        <div className="relative max-w-7xl mx-auto">
          <div className="text-center">
            <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
              What We Do
            </h2>
            <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
              It takes a team with know-how and connections to bring complex
              projects to completion
            </p>
          </div>
          <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
            <div
              className="flex flex-col rounded-lg shadow-lg overflow-hidden"
              style={{ boxShadow: "0 7px 15px  rgba(0,0,0,.5)" }}
            >
              <div className="flex-shrink-0">
                <img
                  className="h-48 w-full object-cover"
                  src="https://images.pexels.com/photos/1769295/pexels-photo-1769295.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
                  alt=""
                />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-sm font-medium text-indigo-600">
                    Financing
                  </p>
                  <a href="#" className="block mt-2">
                    <p className="mt-3 text-base text-gray-500">
                      Traditional vehicles for finance just don’t work when risk
                      levels and uncertainty are high. Through our partners, DFD
                      brings new ideas and new opportunities to clients around
                      the globe.
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="flex flex-col rounded-lg shadow-lg overflow-hidden"
              style={{ boxShadow: "0 7px 15px  rgba(0,0,0,.5)" }}
            >
              <div className="flex-shrink-0">
                <img
                  className="h-48 w-full object-cover"
                  src="https://images.pexels.com/photos/1334605/pexels-photo-1334605.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
                  alt=""
                />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-sm font-medium text-indigo-600">
                    Development
                  </p>
                  <a href="#" className="block mt-2">
                    <p className="mt-3 text-base text-gray-500">
                      DFD takes pride in its ability to realize development
                      opportunities in advance of the marketplace and pursues
                      concrete innovations in design and construction.
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="flex flex-col rounded-lg shadow-lg overflow-hidden"
              style={{ boxShadow: "0 7px 15px  rgba(0,0,0,.5)" }}
            >
              <div className="flex-shrink-0">
                <img
                  className="h-48 w-full object-cover"
                  src="https://images.pexels.com/photos/2503500/pexels-photo-2503500.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
                  alt=""
                />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-sm font-medium text-indigo-600">
                    Consulting
                  </p>
                  <a href="#" className="block mt-2">
                    <p className="mt-3 text-base text-gray-500">
                      DFD is committed to making the necessary connections for
                      developing and managing successful projects and
                      partnerships.
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
