import React from "react";
import WhatWeDoLanding from "../components/content/whatWeDo/WhatWeDoLanding";
import LogoSection from "../components/content/whatWeDo/LogoSection";
import WhatWeDo from "../components/content/whatWeDo/WhatWeDoContent";

export default class WhatWeDoPage extends React.Component {
  render() {
    return (
      <div>
        <WhatWeDoLanding />
        <WhatWeDo />
        <LogoSection />
      </div>
    );
  }
}
