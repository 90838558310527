import React from "react";
import InvestmentLanding from "../components/investment/InvestmentLanding";
import InvestmentContent from "../components/investment/InvestmentContent";
import InvestmentBottom from "../components/investment/InvestmentBottom"

export default class InvestmentCriteria extends React.Component {
  render() {
    return (
      <div>
        <InvestmentLanding />
        <InvestmentContent />
        <InvestmentBottom />
      </div>
    );
  }
}
