import React, { Component } from "react";
import Flyover from "../../..//media/Flyover.mp4";

class LandingHero extends React.Component {
  render() {
    return (
      <main style={{ minHeight: "100vh", minWidth: "100vw" }}>
        <div>
          <div className="relative" style={{ margin: "0" }}>
            {/* <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden"> */}
            <video
              src={Flyover}
              autoplay="autoplay"
              loop="loop"
              playsinline
              muted
              style={{ zIndex: "-99" }}
            />
            <div className="relative"  style={{ paddingTop: "30vh"}}>
              <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl ">
                <span
                  className="block text-white"
                  style={{
                    textShadow:
                      "2px 7px 5px rgba(0,0,0,1), 0px -4px 10px rgba(255,255,255,.5"
                  }}
                >
                  A Chicago Based{" "}
                </span>
                <span
                  className="block text-white"
                  style={{
                    textShadow:
                      "2px 7px 5px rgba(0,0,0,1), 0px -4px 10px rgba(255,255,255,.5"
                  }}
                >
                  Real Estate Development
                </span>
                <span
                  className="block text-white"
                  style={{
                    textShadow:
                      "2px 7px 5px rgba(0,0,0,1), 0px -4px 10px rgba(255,255,255,.5"
                  }}
                >
                  and Structured Finance Company
                </span>
              </h1>
              {/* <p className="mt-6 max-w-lg mx-auto text-center text-xl text-indigo-200 sm:max-w-3xl" style={{textShadow:"2px 7px 5px rgba(0,0,0,0.3), 0px -4px 10px rgba(255,255,255,0.3"}}>
                Through financing and development activities, we strive to leave our mark on a city and build it into a beacon of hope shining upon the hill
              </p> */}
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default LandingHero;
