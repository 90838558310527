import { render } from "@testing-library/react";
import React from "react";

export default class LandingAproach extends React.Component {
  render() {
    return (
      <div className="relative bg-white pt-16 pb-32 overflow-hidden">
        <div className="relative" style={{ paddingTop: "9rem" }}>
          <div className="mt-24">
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
              <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                <div>
                  <div className="mt-6">
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                      Our Approach
                    </h2>
                    <p className="mt-4 text-lg text-gray-500">
                      We seek to create positive economic impact and long-term
                      value for our investors, the companies we invest in and
                      the communities in which we work. We do this by using our
                      extraordinary network of people, and flexible capital to
                      help companies solve problems. We build strategic
                      partnerships with our clients, creating solutions designed
                      to meet their specific objectives. Our scale and breadth
                      of relationships, coupled with our global reach, allow us
                      to identify optimal investment opportunities.
                    </p>
                    <div className="mt-8 border-t border-gray-200 pt-6">
                      <blockquote>
                        <div>
                          <p className="text-base text-gray-500">
                            &ldquo;Chicago is a town, a city that doesn't ever
                            have to measure itself against any other city. Other
                            places have to measure themselves against it. It’s
                            big, it’s outgoing, it’s tough, it’s opinionated,
                            and everybody’s got a story.&rdquo;
                          </p>
                        </div>
                        <footer className="mt-3">
                          <div className="flex items-center space-x-3">
                            <div className="text-base font-medium text-gray-700">
                              Anthony Bourdain
                            </div>
                          </div>
                        </footer>
                      </blockquote>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
                <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                  <img
                    className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                    src="https://images.pexels.com/photos/7238260/pexels-photo-7238260.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
                    alt="Customer profile user interface"
                  />
                </div>
              </div>
            </div>
            <div style={{ paddingTop: "10rem" }}></div>
          </div>
        </div>
      </div>
    );
  }
}
