import React from "react";
import axios from "axios";

export default class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      input: {},
      agreed: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handlePrivacyAgreementToggle() {
    console.log("State" + this.state.agreed);
    this.setState(function(state) {
      return {
        agreed: !this.state.agreed
      };
    });
  }

  determineClass(prop) {
    console.log("calling determine");
    if (prop === "button") {
      if (this.state.agreed) {
        return "bg-indigo-600 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500";
      } else {
        return "bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500";
      }
    }
    if (prop === "span") {
      if (this.state.agreed) {
        return "translate-x-5 inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200";
      } else {
        return "translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200";
      }
    }
  }

  handleSubmit(event) {
    event.preventDefault();

    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept"
    };

    const message = {
      code: "f2YXpF0qWVRQc6Blvz2Q",
      toEmail: "julio@groundswellmoves.com",
      firstName: this.state.input.firstname,
      lastName: this.state.input.lastname,
      company: this.state.input.company,
      email: this.state.input.email,
      phone: this.state.input.phone,
      message: this.state.input.message
    };

    axios
      .post(
        `https://hermes.groundswellmoves.com/api/v1/hermes/contact`,
        {
          code: "f2YXpFOqWVRQc6Blvz2Q",
          toEmail: "julio@groundswellmoves.com",
          firstName: this.state.input.firstname,
          lastName: this.state.input.lastname,
          company: this.state.input.company,
          email: this.state.input.email,
          phone: this.state.input.phone,
          message: this.state.input.message
        },
        { headers }
      )
      .then(res => {
        console.log(res);
      });

    // axios.post(`http://localhost:8080/api/v1/hermes/contact`,
    //   {
    //     code: "f2YXpFOqWVRQc6Blvz2Q",
    //     toEmail: "matt@groundswellmoves.com",
    //     firstName: this.state.input.firstname,
    //     lastName: this.state.input.lastname,
    //     company: this.state.input.company,
    //     email: this.state.input.email,
    //     phone: this.state.input.phone,
    //     message: this.state.input.message
    // })
    // .then(res => {
    //   console.log(res);
    // })

    let input = {};
    input["firstname"] = "";
    input["lastname"] = "";
    input["company"] = "";
    input["email"] = "";
    input["phone"] = "";
    input["message"] = "";
    this.setState({ input: input });
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;

    this.setState({
      input
    });
  }

  render() {
    return (
      <div className="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
        <div className="relative max-w-xl mx-auto">
          <svg
            className="absolute left-full transform translate-x-1/2"
            width="404"
            height="404"
            fill="none"
            viewBox="0 0 404 404"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="85737c0e-0916-41d7-917f-596dc7edfa27"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  style={{
                    color: "rgb(153, 221, 255)"
                  }}
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="404"
              fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
            />
          </svg>
          <svg
            className="absolute right-full bottom-0 transform -translate-x-1/2"
            width="404"
            height="404"
            fill="none"
            viewBox="0 0 404 404"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="85737c0e-0916-41d7-917f-596dc7edfa27"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="404"
              fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
            />
          </svg>
          <div
            className="shadow-lg"
            style={{
              padding: "2rem 4rem",
              boxShadow: "0 7px 15px  rgba(0,0,0,.5)"
            }}
          >
            <div className="text-center">
              <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Want to discuss a project?
              </h2>
            </div>
            <div className="mt-12">
              <form
                onSubmit={this.handleSubmit}
                className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
              >
                <div>
                  <label
                    for="first_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    First name
                  </label>
                  <div className="mt-1 shadow-md">
                    <input
                      type="text"
                      name="firstname"
                      value={this.state.input.firstname}
                      onChange={this.handleChange}
                      id="firstname"
                      autocomplete="given-name"
                      className="py-3 px-4 block w-full shadow-md border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div>
                  <label
                    for="last_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Last name
                  </label>
                  <div className="mt-1 shadow-md">
                    <input
                      type="text"
                      name="lastname"
                      id="lastname"
                      value={this.state.input.lastname}
                      onChange={this.handleChange}
                      autocomplete="family-name"
                      className="py-3 px-4 block w-full shadow-md border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    for="company"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Company
                  </label>
                  <div className="mt-1 shadow-md">
                    <input
                      type="text"
                      name="company"
                      id="company"
                      value={this.state.input.company}
                      onChange={this.handleChange}
                      autocomplete="organization"
                      className="py-3 px-4 block w-full shadow-md border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2 d">
                  <label
                    for="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email
                  </label>
                  <div className="mt-1 shadow-md">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      value={this.state.input.email}
                      onChange={this.handleChange}
                      autocomplete="email"
                      className="py-3 px-4 block w-full shadow-md border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    for="phone_number"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Phone Number
                  </label>
                  <div className="mt-1 relative rounded-md shadow-md">
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      value={this.state.input.phone}
                      onChange={this.handleChange}
                      autocomplete="tel"
                      className="py-3 px-4 block w-full border-gray-300 shadow-md rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    for="message"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Message
                  </label>
                  <div className="mt-1 shadow-md">
                    <textarea
                      id="message"
                      name="message"
                      value={this.state.input.message}
                      onChange={this.handleChange}
                      rows="4"
                      className="py-3 px-4 block w-full shadow-md border-gray-300 rounded-md"
                    ></textarea>
                  </div>
                </div>
                {/* <div className="sm:col-span-2">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      <button
                        type="button"
                        onClick={() => this.handlePrivacyAgreementToggle()}
                        className={this.determineClass("button")}
                        aria-pressed="false"
                      >
                        <span className="sr-only">Agree to policies</span>
                        <span
                          aria-hidden="true"
                          className={this.determineClass("span")}
                        ></span>
                      </button>
                    </div>
                    <div className="ml-3">
                      <p className="text-base text-gray-500">
                        By selecting this, you agree to the
                        <a
                          href="#"
                          className="font-medium text-gray-700 underline"
                        >
                          Privacy Policy
                        </a>
                      </p>
                    </div>
                  </div>
                </div> */}
                <div className="sm:col-span-2">
                  <button
                    type="submit"
                    className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    style={{
                      backgroundColor: "rgb(105, 213, 250)"
                    }}
                  >
                    Let's talk
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
