import React from "react";
import "./App.css";
import Navbar from "./components/common/Navbar";
import Footer from "./components/common/Footer";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import HomePage from "./views/HomePage";
import ContactUsPage from "./views/ContactUsPage";
import WhatWeDoPage from "./views/WhatWeDoPage";
import InvestmentCriteria from "./views/InvestmentCriteria";
import Pressers from "./views/Pressers";
import AboutUsView from "./views/AboutUsView";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: "/"
    };

    this.updateSelectedRoute = this.updateSelectedRoute.bind(this);
    this.determineSelectedRoute = this.determineSelectedRoute.bind(this);
  }

  determineSelectedRoute = routeName => {
    if (routeName === this.state.selected) {
      return "border-indigo-500 text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium";
    } else {
      return "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium";
    }
  };

  updateSelectedRoute = routeName => {
    console.log("Update:" + routeName);
    this.setState(function(state) {
      console.log("Updating");
      return {
        selected: routeName
      };
    });
  };

  componentDidUpdate() {
    console.log(this.state.selected);
    localStorage.setItem("state", JSON.stringify(this.state));
  }

  componentWillMount() {
    const rehydrate = JSON.parse(localStorage.getItem("state"));
    this.setState(rehydrate);
  }

  render() {
    return (
      <div>
        <Navbar
          update={this.updateSelectedRoute.bind(this)}
          selected={this.state.selected}
        />
        <BrowserRouter>
          <Switch>
            {/* <Route path="/what-we-do">
              <WhatWeDoPage />
            </Route> */}
            <Route path="/about">
              <AboutUsView />
            </Route>
            <Route path="/contact">
              <ContactUsPage />
            </Route>
            {/* <Route path="/investment">
              <InvestmentCriteria />
            </Route> */}
            <Route path="/news">
              <Pressers />
            </Route>
            <Route path="/">
              <HomePage />
            </Route>
          </Switch>
        </BrowserRouter>
        <Footer />
      </div>
    );
  }
}

export default App;
