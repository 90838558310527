import React, { Component } from "react";
import logo from "../../media/logo.png";

export default class Footer extends React.Component {
  render() {
    return (
      <footer className="bg-white" aria-labelledby="footerHeading">
        <h2 id="footerHeading" className="sr-only">
          Footer
        </h2>
        <div className="max-w-8xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            <div className="space-y-8 xl:col-span-1">
              <a href="/">
                <img className="h-10" src={logo} alt="Company name" />
              </a>
              <p className="text-gray-500 text-base">
                A Chicago based real estate development and structured finance
                firm
              </p>
              <div className="flex space-x-6">
                <a href="#!" role="button">
                  <i className="fab fa-twitter"></i>
                </a>

                <a href="#!" role="button">
                  <i className="fab fa-instagram"></i>
                </a>

                <a href="#!" role="button">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
            <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  {/* <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                    Website
                  </h3>
                  <ul className="mt-4 space-y-4">
                    <li>
                      <a
                        href=""
                        className="text-base text-gray-500 hover:text-gray-900"
                      >
                        Home
                      </a>
                    </li>

                    <li>
                      <a
                        href="#"
                        className="text-base text-gray-500 hover:text-gray-900"
                      >
                        Financing
                      </a>
                    </li>

                    <li>
                      <a
                        href="#"
                        className="text-base text-gray-500 hover:text-gray-900"
                      >
                        Consulting
                      </a>
                    </li>
                  </ul> */}
                </div>
                <div className="mt-12 md:mt-0">
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                    Company
                  </h3>
                  <ul className="mt-4 space-y-4">
                    <li>
                      <a
                        href="contact"
                        className="text-base text-gray-500 hover:text-gray-900"
                      >
                        Contact Us
                      </a>
                    </li>
                    <li>
                      <a
                        href="/news"
                        className="text-base text-gray-500 hover:text-gray-900"
                      >
                        News
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                    Company
                  </h3>
                  <ul className="mt-4 space-y-4"> */}
              {/* <li>
                      <a
                        href="#"
                        className="text-base text-gray-500 hover:text-gray-900"
                      >
                        About
                      </a>
                    </li> */}

              {/* <li>
                      <a
                        href="/news"
                        className="text-base text-gray-500 hover:text-gray-900"
                      >
                        News
                      </a>
                    </li>
                  </ul>
                </div> */}
              {/* <div className="mt-12 md:mt-0">
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                    Legal
                  </h3>
                  <ul className="mt-4 space-y-4">
                    <li>
                      <a
                        href="#"
                        className="text-base text-gray-500 hover:text-gray-900"
                      >
                        Privacy
                      </a>
                    </li>

                    <li>
                      <a
                        href="#"
                        className="text-base text-gray-500 hover:text-gray-900"
                      >
                        Terms
                      </a>
                    </li>
                  </ul>
                </div> */}
            </div>
          </div>
        </div>
        <div className="mt-12 border-t border-gray-200 pt-8">
          <p className="text-base text-gray-400 xl:text-center">
            &copy; 2021 Dearborn Finance &amp; Development, LLC. All rights
            reserved.
          </p>
        </div>
      </footer>
    );
  }
}
