import React from 'react'

export default class CTA extends React.Component{
    render() {
        return (
            <div>
                <div class="bg-indigo-50">
                    <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between">
                        <h2 class="text-3xl font-extrabold tracking-tight text-gray-900 md:text-4xl">
                        <span class="block">Ready to learn more?</span>
                        <span class="block text-indigo-600">Reach out to us today!</span>
                        </h2>
                        <div class="mt-8 flex lg:mt-0 lg:flex-shrink-0">
                        <div class="inline-flex rounded-md shadow">
                            <a href="/contact" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700">
                            Let's Connect
                            </a>
                        </div>
                        </div>
                </div>
                    </div>
            </div>
        )
    }
}