import React from 'react'
import Contact from '../components/contact/Contact'

export default class ContactUsPage extends React.Component{
    render() {
        return (
            <div>
                <Contact/>
            </div>
        )
    }
}